import React from 'react';

const AppStonji: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.2543 7.36942L28.2022 11.7076L27.9867 12.5939L27.873 13.0659L23.8293 29.7478C23.4458 31.3303 21.8524 32.2998 20.2725 31.9161L8.67253 29.1009C7.09271 28.7189 6.12208 27.1246 6.50558 25.5438L11.9306 3.1671C12.3124 1.58635 13.9058 0.615144 15.4856 0.997174L21.5537 2.47096L27.0839 3.8123C28.6671 4.19603 29.6378 5.78867 29.2543 7.36942Z"
        fill="#1D232A"
      />
      <path
        d="M29.2547 7.36989L28.2026 11.7081L22.4467 6.50905L19.9387 4.24065C20.2391 4.0335 20.8432 3.34415 21.5542 2.46973L27.0844 3.81107C28.6676 4.1965 29.6382 5.78914 29.2547 7.36989Z"
        fill="#42EC9A"
      />
      <path
        d="M27.9889 12.594L27.8752 13.066C26.2157 11.582 24.5561 10.0981 22.8965 8.61409C22.81 8.53599 22.8388 8.37808 22.9186 8.30847C23.0153 8.22697 23.1358 8.25074 23.224 8.33054C23.7738 8.82463 24.3287 9.31873 24.8802 9.81282C25.9136 10.7399 26.9504 11.6669 27.9889 12.594Z"
        fill="#42EC9A"
      />
      <path
        d="M16.6513 31.0383L4.91215 28.1892C3.33233 27.8055 2.36171 26.2129 2.74521 24.6304L8.1702 2.25199C8.5537 0.671236 10.1454 -0.299971 11.7269 0.0837565L23.4661 2.93285C25.0459 3.31657 26.0165 4.90921 25.633 6.49166L20.208 28.8701C19.8228 30.4508 18.2311 31.4221 16.6513 31.0383Z"
        fill="#00A868"
      />
      <path
        d="M23.3452 7.21377L9.86914 3.94336L5.03643 23.8806L18.5125 27.1511L23.3452 7.21377Z"
        fill="#42EC9A"
      />
      <path
        d="M5.6401 23.5117L10.2372 4.54621L11.0349 4.73979L6.43776 23.7052L5.6401 23.5117Z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
      />
      <path
        d="M17.1109 4.11941C17.451 4.11941 17.7268 3.84346 17.7268 3.50306C17.7268 3.16267 17.451 2.88672 17.1109 2.88672C16.7707 2.88672 16.4949 3.16267 16.4949 3.50306C16.4949 3.84346 16.7707 4.11941 17.1109 4.11941Z"
        fill="#1D232A"
      />
      <path
        d="M13.9457 18.4453C13.9203 18.554 13.9915 18.661 14.1018 18.6797C15.775 18.9632 17.4023 17.8986 17.7943 16.2228C18.1863 14.5469 17.2021 12.8694 15.5781 12.3804C15.4712 12.3481 15.3592 12.4127 15.3338 12.5213L15.0572 13.7031C15.0317 13.8134 15.0945 13.9221 15.1997 13.9612C15.9582 14.2396 16.4062 15.0461 16.2179 15.8526C16.0295 16.6591 15.2693 17.1821 14.4667 17.0938C14.3547 17.0819 14.2495 17.1515 14.224 17.2619L13.9457 18.4453Z"
        fill="#1D232A"
      />
      <path
        d="M13.3625 13.5803C13.3031 13.4852 13.1792 13.4563 13.0842 13.514L11.6554 14.5005C11.574 14.5549 11.5129 14.6398 11.4874 14.7433C11.4637 14.8469 11.4806 14.9488 11.5298 15.0354L12.3732 16.555C12.4326 16.6501 12.5565 16.679 12.6515 16.6195C12.699 16.5907 12.7296 16.5431 12.7414 16.4939L13.388 13.7331C13.3998 13.6821 13.3913 13.6278 13.3625 13.5803Z"
        fill="#1D232A"
      />
    </svg>
  );
};

export default AppStonji;
